/* noto-sans-kr-300 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/noto-sans-kr-v21-latin_korean-300.eot');
  src: local(''), url('../../assets/fonts/noto-sans-kr-v21-latin_korean-300.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-300.woff2') format('woff2'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-300.woff') format('woff'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-300.ttf') format('truetype'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-300.svg#NotoSansKR') format('svg');
  unicode-range: U+AC00-D7A3;
}

/* noto-sans-kr-regular - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/noto-sans-kr-v21-latin_korean-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../assets/fonts/noto-sans-kr-v21-latin_korean-regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-regular.woff2') format('woff2'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-regular.woff') format('woff'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-regular.ttf') format('truetype'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-regular.svg#NotoSansKR') format('svg');
  unicode-range: U+AC00-D7A3;
}

/* noto-sans-kr-500 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/noto-sans-kr-v21-latin_korean-500.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../assets/fonts/noto-sans-kr-v21-latin_korean-500.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-500.woff2') format('woff2'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-500.woff') format('woff'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-500.ttf') format('truetype'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-500.svg#NotoSansKR') format('svg');
  unicode-range: U+AC00-D7A3;
}

/* noto-sans-kr-700 - latin_korean */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/noto-sans-kr-v21-latin_korean-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../assets/fonts/noto-sans-kr-v21-latin_korean-700.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-700.woff2') format('woff2'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-700.woff') format('woff'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-700.ttf') format('truetype'),
    url('../../assets/fonts/noto-sans-kr-v21-latin_korean-700.svg#NotoSansKR') format('svg');
  unicode-range: U+AC00-D7A3;
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/fonts/open-sans-v27-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../assets/fonts/open-sans-v27-latin-regular.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/open-sans-v27-latin-regular.woff2') format('woff2'),
    url('../../assets/fonts/open-sans-v27-latin-regular.woff') format('woff'),
    url('../../assets/fonts/open-sans-v27-latin-regular.ttf') format('truetype'),
    url('../../assets/fonts/open-sans-v27-latin-regular.svg#OpenSans') format('svg');
}
/* open-sans-500 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../../assets/fonts/open-sans-v27-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../assets/fonts/open-sans-v27-latin-500.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/open-sans-v27-latin-500.woff2') format('woff2'),
    url('../../assets/fonts/open-sans-v27-latin-500.woff') format('woff'),
    url('../../assets/fonts/open-sans-v27-latin-500.ttf') format('truetype'),
    url('../../assets/fonts/open-sans-v27-latin-500.svg#OpenSans') format('svg');
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/fonts/open-sans-v27-latin-600.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../assets/fonts/open-sans-v27-latin-600.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/open-sans-v27-latin-600.woff2') format('woff2'),
    url('../../assets/fonts/open-sans-v27-latin-600.woff') format('woff'),
    url('../../assets/fonts/open-sans-v27-latin-600.ttf') format('truetype'),
    url('../../assets/fonts/open-sans-v27-latin-600.svg#OpenSans') format('svg');
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/fonts/open-sans-v27-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''), url('../../assets/fonts/open-sans-v27-latin-700.eot?#iefix') format('embedded-opentype'),
    url('../../assets/fonts/open-sans-v27-latin-700.woff2') format('woff2'),
    url('../../assets/fonts/open-sans-v27-latin-700.woff') format('woff'),
    url('../../assets/fonts/open-sans-v27-latin-700.ttf') format('truetype'),
    url('../../assets/fonts/open-sans-v27-latin-700.svg#OpenSans') format('svg');
}

@use '../../abstracts/' as *;

.tv-program-episode-detail {
  display: grid;
  grid-template-columns: 900px auto;
  grid-template-rows: repeat(auto-fit, auto);
  row-gap: 16px;
  column-gap: 30px;
  max-width: 1280px;
  margin: 16px auto 140px;

  overflow: hidden;
  transition: grid 200ms;
}
.tv-program-episode-detail--list-off {
  @extend .tv-program-episode-detail;
  grid-template-columns: 100% auto;

  .tv-program-episode-detail__side {
    opacity: 0;
    transition: opacity 200ms;
  }
}

.tv-program-episode-detail__title {
  grid-column: span 2;
  margin-bottom: 16px;
  font-size: 26px;
  font-weight: 700;
  > span {
    color: #ee174f;
  }
}

.tv-program-episode-detail__article {
  display: grid;
  grid-template-columns: 1fr 120px;
  grid-template-rows: auto 40px auto auto;
  row-gap: 16px;
}

.tv-program-episode-detail__article-player {
  grid-column: span 2;
  width: 100%;
  aspect-ratio: 16 / 9;
  iframe {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.tv-program-episode-detail__article-date {
  grid-column: span 2;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > time {
    font-size: 15px;
    @include els;
  }
  > button {
    height: 100%;
    font-weight: 700;
    color: #fff;
    border-radius: 20px;
    background-color: #1777ee;
    padding: 0 32px;
  }
}
.tv-program-episode-detail__article-title {
  grid-column: span 2;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.4;
  white-space: pre-line;
}

.tv-program-episode-detail__article-content {
  > p {
    line-height: 22px;
    margin-bottom: 8px;
    word-break: keep-all;
    white-space: pre-wrap;
  }
  > a {
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 6px;
    position: relative;
    font-size: 18px;
    font-weight: 700;

    &::before {
      content: '';
      width: 16px;
      aspect-ratio: 1;
      background: url('../../../assets/images/icon/ico_home_k.svg') no-repeat 50% 50%;
    }
  }
}
.tv-program-episode-detail__article-copyright {
  grid-column: span 2;
  margin-top: 30px;
  border-top: 1px solid rgba($color: #000000, $alpha: 0.2);
  padding-top: 20px;
  text-align: right;
  > p {
    font-size: 14px;
    margin-bottom: 8px;
  }
  > img {
    display: block;
    width: 144px;
    margin-left: auto;
  }
}

.tv-program-episode-detail__side-latest {
  > h2 {
    font-size: 22px;
    font-weight: 700;
    line-height: 22px;
    word-break: keep-all;
  }
  > hr {
    height: 1px;
    margin-block: 16px;
    background-color: #b8b8b8;
  }

  > ul {
    position: relative;
    height: 600px;
    padding-right: 4px;
    overflow-y: auto;
    &::after,
    &::before {
      content: '';
      position: sticky;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #b8b8b8;
    }
    &::before {
      top: 0;
      left: 0;
    }
    &::after {
      bottom: 0;
      left: 0;
    }
    &:has(> li:first-child:hover) {
      &::before {
        background-color: black;
      }
    }
    &:has(> li:last-child:hover) {
      &::after {
        background-color: black;
      }
    }
  }

  > ul > li {
    border: 1px solid #b8b8b8;
    border-bottom-width: 0px;
    &[data-active='true'] {
      background-color: #eee;
    }
    &:first-child {
      border-top-width: 0px;
    }
    &:last-child {
      border-bottom-width: 0px;
    }
    &:hover {
      border-color: black;
      + li {
        border-top-color: black;
      }
    }
  }
  > ul > li > a {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-template-rows: 1fr auto;
    gap: 10px;
    padding: 12px;
    > img {
      grid-row: span 2;
      width: 100%;
      aspect-ratio: 16 / 9;
    }
    > h3 {
      font-size: 15px;
      line-height: 1.2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      overflow: hidden;
      word-break: keep-all;
    }
    > time {
      font-size: 15px;
    }
  }
}

@charset 'utf-8';

* {
  margin: 0;
  padding: 0;
  border: 0;
  // font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
*:before,
*:after {
  box-sizing: border-box;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
input,
textarea,
select {
  display: block;
  border-radius: 0;
  outline: none;
}
fieldset {
  border: none;
}
ol,
ul,
li {
  list-style: none;
}
img {
  vertical-align: middle;
  border: none;
}
img,
video {
  max-width: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
address,
em,
i {
  font-style: normal;
}
a {
  text-decoration: none;
  color: inherit;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}

@use '../abstracts' as *;

html {
  min-width: 1320px;
  font-size: 16px;
}
* {
  letter-spacing: -0.1px;
}

body {
  font: 1rem/1.6 'Noto Sans KR', 'Open Sans', Helvetica, Arial, sans-serif;
  color: $font_color_0;
  font-weight: 300;
}

button {
  cursor: pointer;
  font: 1rem/1.6 'Noto Sans KR', 'Open Sans', Helvetica, Arial, sans-serif;
  color: $font_color_0;
  outline: none;
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
}

.app {
  min-height: 100vh;
}

.time-fs-fixed {
  font-size: 12px !important;
}

@media (max-width: 424px) {
  body {
    font: 0.875em/1.6 'Noto Sans KR', 'Open Sans', Helvetica, Arial, sans-serif;
    color: $font_color_0;
  }
}

.clearfix::after {
  content: '';
  display: table;
  clear: both;
}
.react-datepicker-popper {
  z-index: 2;
}
.sr-only {
  position: absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  margin: -1px;
}

.style-scroll {
  &::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  &::-webkit-scrollbar-thumb {
    background: $gray_line_4;
    border-radius: 3px;
    background-clip: padding-box;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
}
.swiper-button-disabled {
  opacity: 0.2;
}
.nav-style-1 .swiper-button-prev,
.nav-style-1 .swiper-button-next,
.swiper-button-prev,
.swiper-button-next {
  &::after {
    display: none;
    content: '';
    width: 100%;
    aspect-ratio: 1;
    transform: rotate(-90deg);
    background: url(../../assets/images/icon/ico_down-arrow-big_w.svg) no-repeat 50% 50%;
    background-color: #34373c;
    background-size: contain;
  }
}
.nav-style-1 .swiper-button-prev,
.swiper-button-prev {
  &::after {
    transform: rotate(90deg);
  }
}

.swiper-container:hover {
  .swiper-button-next::after,
  .swiper-button-prev::after {
    display: block;
  }
}
.swiper-button-auto {
  display: inline-block;
  width: 14px;
  aspect-ratio: 1;
  margin: 0 4px;
  background: url(../../assets/images/icon/ico-play_icon_w.svg) no-repeat 50% 50%;
  background-size: contian;

  cursor: pointer;
  &[data-play='true'] {
    background: url(../../assets/images/icon/ico-pause_icon_w.svg) no-repeat 50% 50%;
  }
}
.swiper-pagination-fraction {
  padding: 0 10px;
  text-align: right;
}
@media (max-width: 1023px) {
  .style-scroll {
    &::-webkit-scrollbar {
      width: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background: $gray_line_4;
      border-radius: 2px;
      background-clip: padding-box;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
}

@keyframes fade-in-fwd {
  0% {
    -webkit-transform: translateZ(-80px);
    transform: translateZ(-80px);
    opacity: 0.25;
  }

  100% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes fade-in-top {
  0% {
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    opacity: 0.25;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-right {
  0% {
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    opacity: 0.25;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0.25;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0.25;
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.hideme {
  opacity: 0.25;
}

.showme {
  -webkit-animation: fade-in-fwd 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-fwd 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  &.top {
    -webkit-animation: fade-in-top 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-top 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  &.right {
    -webkit-animation: fade-in-right 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-right 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  &.bottom {
    -webkit-animation: fade-in-bottom 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-bottom 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  &.left {
    -webkit-animation: fade-in-left 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: fade-in-left 1s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
}

/* Scroll hidden */
.hidden-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

//ico
.ico_play {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    bottom: 8px;
    right: 8px;
    width: 60px;
    height: 60px;
    background: url(../../assets/images/icon/ico_btnPlay.svg) no-repeat 50% 50%;
    background-size: contain;
    @include easeInOutCirc(opacity, 300ms, 0);
    opacity: 0;
  }
  &:hover,
  &:focus {
    &::after {
      opacity: 0.9;
    }
  }
}

#popupShare {
  position: relative;
  padding: 16px 24px 24px;
  background-color: #fff;
  border-radius: 10px;
  -webkit-box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 10%) 0px 8px 24px,
    rgb(17 17 26 / 10%) 0px 16px 56px;
  box-shadow: rgb(17 17 26 / 10%) 0px 4px 16px, rgb(17 17 26 / 10%) 0px 8px 24px, rgb(17 17 26 / 10%) 0px 16px 56px;
  &::after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    top: 12px;
    left: -6px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 10px solid #fff;
    z-index: 9999;
  }
  .share-x {
    width: 40px;
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
  }

  .popupShare {
    &_title {
      font-size: 18px;
      padding-bottom: 4px;
      margin-bottom: 14px;
      border-bottom: 1px solid #e4e4e4;
      .style-font {
        font-weight: 700;
      }
    }
    &_btnWrap {
      display: flex;
      gap: 8px;
      .button {
        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

        &::after {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
        &.btnFacebook {
          &::after {
            background: url(../../assets/images/icon/ico_facebook_c.svg) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &.btnTwitter {
          &::after {
            background: url(../../assets/images/icon/ico_twitter_c.svg) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &.btnInstagram {
          &::after {
            background: url(../../assets/images/icon/ico_instagram_c.svg) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &.btnYoutube {
          &::after {
            background: url(../../assets/images/icon/ico_youtube_c.svg) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &.btnSpotify {
          &::after {
            background: url(../../assets/images/icon/ico_spotify_c.svg) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &.btnNaverTv {
          background-color: #fff;
          &::after {
            width: 24px;
            height: 24px;
            left: calc(50% + 2px);
            background: url(../../assets/images/icon/ico_navertv_c.svg) no-repeat 50% 50%;
            background-size: contain;
          }
        }
        &.btnLink {
          background-color: #fff;
          &::after {
            width: 24px;
            height: 24px;
            background: url(../../assets/images/icon/ico_link_g.svg) no-repeat 50% 50%;
            background-size: contain;
          }
        }
      }
    }
  }
}

//table
.table {
  table-layout: fixed;
  border-top: 3px solid $gray_line_5;
  .style_center {
    text-align: center;
  }
  &_body {
    .table_row {
      &:last-of-type {
        border-bottom: 1px solid $gray_line_2;
      }
    }
  }
  &_row {
    height: 64px;
    border-top: 1px solid $gray_line_2;
    &.notice {
      background-color: $sub_color_1;
    }
    &_head {
      height: 100%;
      vertical-align: middle;
      &.track-part {
        font-size: 20px;
      }
      &.track {
        text-align: left;
        padding-left: 16px;
      }
    }
    &_data {
      height: 100%;
      padding: 0 1em;
      vertical-align: middle;
      &.track {
        padding-left: calc(12% + 16px);
      }
    }
  }
}

.btnViewAll {
  background: black;
  color: white;
  padding: 2px 12px;
  border-radius: 5px;
  font-size: 14px;
}

// animation
@keyframes loopText_1 {
  0% {
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
  }
}
@keyframes loopText_2 {
  0% {
    transform: translateX(0%);
    -webkit-transform: translateX(0%);
  }
  100% {
    transform: translateX(-200%);
    -webkit-transform: translateX(-200%);
  }
}

@keyframes blinkEffect {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

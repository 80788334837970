.top-banner-container {
  position: relative;

  .gov {
    width: 100%;

    position: fixed;
    top: 0;
    z-index: 99999;

    border-bottom: 1px solid #ccc;
    background-color: white;
  }
  .gov__content {
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 1280px;
    height: var(--top-banner-gov-h);

    margin-inline: auto;
    padding-inline: 6px 20px;
    > p {
      font-size: 12px;
      line-height: 12px;
    }
  }

  .banner {
    height: 80px;

    position: relative;
    margin-block-start: var(--top-banner-gov-h);
  }

  .banner__item {
    > img {
      object-fit: cover;
    }
    .banner__close-box {
      display: flex;
      align-items: center;
      gap: 16px;

      position: absolute;
      top: 50%;
      right: 5%;
      transform: translateY(-50%);

      > label {
        display: flex;
        align-items: center;
        gap: 10px;

        font-size: 15px;
        color: white;
      }
      input {
        width: 15px;
        aspect-ratio: 1;

        appearance: none;

        background: url('../../assets/images/icon/ico_search_check_off.png') no-repeat;

        &:checked {
          background: url('../../assets/images/icon/ico_search_check_on.png') no-repeat;
        }
      }
      > button {
        width: 32px;
        aspect-ratio: 1;

        position: relative;

        &::after,
        &::before {
          content: '';
          width: 2px;
          height: 100%;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transform-origin: left top;

          background-color: white;
        }
        &::after {
          rotate: 45deg;
        }
        &::before {
          rotate: -45deg;
        }
      }
    }
  }
}

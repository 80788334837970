.photo-gallery {
  width: 1280px;
  margin-inline: auto;

  &__loading {
    width: 100%;
    text-align: center;

    > img {
      animation-duration: 6s;
      animation-iteration-count: infinite;
      animation-name: rotate;
      animation-timing-function: linear;
      margin-bottom: 10px;
      width: 100px;
    }

    > p {
      font-weight: 700;
    }
  }

  &__warning {
    text-align: center;
    > img {
      margin-bottom: 10px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, calc((100% - 60px) / 4));
    grid-template-rows: repeat(auto-fill, 1fr);
    row-gap: 60px;
    column-gap: 20px;
  }

  &__link {
    width: 100%;
    > img {
      aspect-ratio: 16 / 9;
      margin-bottom: 16px;
      object-fit: cover;
    }
    > h3 {
      padding-inline: 8px;
      font-size: 18px;
      font-weight: 700;
      overflow: hidden;
      word-break: break-all;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }
    > time {
      display: block;
      margin-right: 8px;
      font-size: 15px;
      color: #6c6c6c;
      text-align: right;
    }
  }
}

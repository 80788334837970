.header__breadcrumb-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: #f4f4f4;
}
.header__breadcrumb {
  width: 1280px;
  margin: 0 auto;
  font-size: 16px;
  font-weight: 500;

  a {
    vertical-align: middle;
  }
  li {
    display: inline-block;
  }
  li:not(:last-child)::after {
    content: '';
    display: inline-block;
    width: 18px;
    aspect-ratio: 1;
    margin-inline: 3px;
    vertical-align: middle;
    background: url(../../assets/images/news/header/ico-arrow-r.png) no-repeat;
    background-size: cover;
  }
  li::first-letter {
    text-transform: capitalize;
  }
}

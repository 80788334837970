#Main {
  margin-top: -90px;
  .main_section {
    width: 1280px;
    margin: 0 auto 72px;
  }
  .main_section.slider {
    width: 100%;
    margin-bottom: 36px;
    min-height: 790px;
    background: black;
    position: relative;
  }
}

.main-section {
  width: 1280px;
  margin: 0 auto 36px;
}
.image-container {
  position: relative;
  margin-bottom: 10px;

  &::after {
    content: '';
    display: block;
    width: 60px;
    aspect-ratio: 1;
    position: absolute;
    bottom: 5px;
    right: 5px;
    background: url(../../assets/images/icon/ico_btnPlay.svg) no-repeat 50% 50%;
    background-size: contain;
    transition: opacity 300ms ease-in-out;
    opacity: 0;
  }
  &:hover,
  &:focus {
    &::after {
      opacity: 0.9;
    }
  }
}

.main-section__schedule {
  @extend .main-section;
  margin-bottom: 72px;
  .section-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .schedule-wrapper {
    width: 960px;
    height: 280px;
  }
  .schedule-title {
    display: grid;
    grid-template-columns: repeat(2, max-content) 1fr;
    grid-template-rows: 45px;
    align-items: center;
    gap: 40px;
    margin-bottom: 10px;
    > h2 {
      grid-row: 1 / 2;
      font-size: 24px;
      > em {
        font-weight: 700;
      }
    }
    > ul {
      grid-row: 1 / 2;
      display: flex;
      height: 100%;

      button {
        height: 100%;
        padding-inline: 16px;
        border: 1px solid #dddddd;
        color: #6c6c6c;
        font-weight: 500;
        &[data-active='true'] {
          border-color: #1b1b1b;
          color: #1b1b1b;
          font-weight: 700;
        }
      }
    }
    .foundation-link {
      grid-row: 1 / 2;
      justify-self: flex-end;
      align-self: flex-end;
      width: 140px;
      height: fit-content;
      padding: 2px 10px;
      border: 1px solid #dddddd;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: #6c6c6c;
    }
  }
  .schedule-content {
    display: flex;
    width: 100%;
    height: calc(100% - 45px - 10px);
    background-color: #f1f1f1;

    > a {
      margin: 16px;
    }
    .live-player {
      @extend .image-container;
      height: 100%;
      aspect-ratio: 16 / 9;
      position: relative;

      > iframe {
        width: 100%;
        height: 100%;
      }
      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: url('../../assets/images/main/player-cover-world.png');
        background-size: contain;
      }
      &[data-img-src='korea']::before {
        background: url('../../assets/images/main/player-cover-korea.png');
      }
      &[data-img-src='un']::before {
        background: url('../../assets/images/main/player-cover-un.png');
      }
      &[data-img-src='radio']::before {
        background: url('../../assets/images/main/player-cover-radio.png');
      }
      &:hover::before {
        opacity: 0;
      }
    }
    .live-schedule {
      flex: 1;
      > a {
        position: relative;
      }
      > a:first-child::after {
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateY(-2%);
        background-color: #ddd;
      }
      > a:last-child .sch-container {
        background-color: #f7f7f7;
      }
      .sch-container {
        display: flex;
        align-items: center;
        height: 50%;
      }
      .time-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        aspect-ratio: 1;
        line-height: 1.2;
        > time {
          font-size: 20px;
        }
        > span {
          font-size: 11px;
          font-weight: 700;
        }
      }
      .info-box {
        flex-grow: 1;
        max-width: 470px;
        > span {
          font-size: 14px;
          font-weight: 700;
          color: #ee174f;
        }
        > h3 {
          font-size: 20px;
          font-weight: 500;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: keep-all;
        }
        > div {
          span {
            padding: 1px 8px;
            font-size: 14px;
            color: #fff;
            &:not(:last-child) {
              margin-right: 4px;
            }
          }
          .live {
            background-color: #ee174f;
          }
          .next {
            background-color: #fa5c00;
          }
          .first {
            background-color: #343434;
          }
        }
      }
    }
  }
  .square-banner {
    display: flex;
    align-items: center;
    width: 300px;

    .img-wrapper {
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .swiper-container {
    padding-top: 25px;
  }
  .swiper-pagination {
    inset: 0 0 auto auto;
    height: 25px;
    text-align: right;
  }
  .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    transition: all 300ms ease-in-out;
    background-color: #000;
    opacity: 0.2;
  }
  .swiper-pagination-bullet-active {
    width: 30px;
    border-radius: 6px;
    background-color: #414141;
    opacity: 1;
  }
}
.main-section__news {
  @extend .main-section;
  margin-bottom: 72px;
  .news-title {
    font-size: 24px;
    margin-bottom: 16px;
    > em {
      font-weight: 700;
    }
  }
  .news-content {
    display: grid;
    grid-template-columns: 605px 290px 340px;
    grid-template-rows: repeat(6, 100px);
    justify-content: space-between;
  }
  .top-news {
    grid-column: 1 / 2;
    grid-row: 1 / 7;
  }
  .news-top-items {
    grid-column: 2 / 3;
    &[data-index='0'] {
      grid-row: 1 / 4;
    }
    &[data-index='1'] {
      grid-row: 4 / 7;
    }
  }
  .list-news {
    display: flex;
    flex-direction: column;
    border-block: 1px solid black;
    grid-column: 3 / 4;
    grid-row: 1 / 7;
    > li {
      height: 100px;
    }
    > li:not(:last-child) {
      border-bottom: 1px solid #c4c4c4;
    }
  }
  .news-items {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .text-ellipsis {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > h3 {
      @extend .text-ellipsis;
      padding-inline: 8px;
      font-size: 24px;
      font-weight: 700;
      line-height: 1.2;
    }
    > p {
      @extend .text-ellipsis;
      padding-inline: 8px;
      white-space: pre-wrap;
      word-break: keep-all;
      -webkit-line-clamp: 5;
      line-clamp: 5;
      line-height: 1.4;
      letter-spacing: -0.2px;
    }
    > div {
      display: flex;
      align-items: center;
      gap: 7px;
      padding-inline: 8px;
      > img {
        width: 15px;
        aspect-ratio: 1;
      }
      > time {
        font-size: 15px;
      }
    }
  }
  .news-top-items {
    display: flex;
    flex-direction: column;
    gap: 12px;
    > h3 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-inline: 8px;
      line-height: 1.2;
      letter-spacing: -0.2px;
    }
    > div {
      display: flex;
      align-items: center;
      gap: 7px;
      padding-inline: 8px;
      > img {
        width: 15px;
        aspect-ratio: 1;
      }
      > time {
        font-size: 15px;
      }
    }
  }
  .news-list-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
    height: 100%;
    > h3 {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.2;
      letter-spacing: -0.2px;
    }
    > div {
      display: flex;
      align-items: center;
      gap: 7px;
      padding-inline: 8px;
      > img {
        width: 15px;
        aspect-ratio: 1;
      }
      > time {
        font-size: 15px;
      }
    }
  }
}
.main-section__recommend-program {
  margin-bottom: 72px;
  background-color: #34373c;
  .reco-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 1280px;
    margin: 0 auto;
    padding-block: 40px 64px;
  }
  .reco-title {
    font-size: 24px;
    color: white;
    > em {
      font-weight: 700;
    }
  }
  .reco-list {
    display: flex;
    justify-content: space-between;
    img {
      width: 180px;
      height: auto;
    }
  }
}
.main-section__most-popular {
  @extend .main-section;
  margin-bottom: 72px;
  .title-box {
    display: flex;
    align-items: flex-end;
    gap: 12px;
    margin-bottom: 16px;
    h2 {
      font-size: 24px;
      em {
        font-weight: 700;
      }
    }
    p {
      font-weight: 500;
    }
  }

  .content-box {
    display: flex;
    margin-bottom: 24px;
    border: 1px solid #e4e4e4;
    &:last-child {
      margin-bottom: 0;
    }

    .program-image {
      aspect-ratio: 1;
      object-fit: cover;
    }
    .swiper-warpper {
      display: flex;
      align-items: center;
      width: calc(1280px - 215px - 2px);
      padding: 0 16px;
    }
    .program-item {
      display: flex;
      flex-direction: column;
      position: relative;
      > img {
        width: 100%;
        height: auto;
      }
      &::after {
        content: '';
        width: 1px;
        height: 200px;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-17px, 0);
        background-color: #e4e4e4;
      }
    }
    .program-title-box {
      > h3 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        line-height: 1.2;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.main-section__editor-pick {
  @extend .main-section;
  margin-bottom: 72px;
  &:has([data-has-item='false']) {
    margin: 0;
  }
  .pick-title {
    display: flex;
    align-items: flex-end;
    gap: 6px;
    margin-bottom: 24px;
    line-height: 1;
    h2 {
      font-size: 24px;
    }
    p {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .pick-slide {
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
    }
    time {
      font-size: 16px;
      letter-spacing: -0.2px;
    }
  }
}
.main-section__banner-line {
  @extend .main-section;
  margin-bottom: 72px;
  &:has([data-has-item='false']) {
    margin: 0;
  }
  .item-wrap {
    position: relative;
  }
}
.main-section__youtube-slide {
  @extend .main-section;
  margin-bottom: 72px;
  .slide-title {
    font-size: 24px;
    margin-bottom: 32px;
  }
  .slide-wrapper {
    padding-inline: 36px;

    img {
      width: 100%;
      aspect-ratio: 1;
      border-radius: 50%;
    }
    p {
      text-align: center;
      white-space: pre;
    }
  }
}
.main-section__foundation {
  @extend .main-section;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .foundation-board-table {
    width: 753px;

    .category-list {
      display: flex;
      gap: 48px;
      margin: 0 8px 8px;

      > button {
        font-size: 24px;
        font-weight: 700;
        &[data-active='true'] {
          color: #ee174f;
        }
      }
    }
    table {
      width: 100%;
      font-size: 16px;
      text-align: center;
      border-top: 2px solid black;
    }
    tbody {
      height: 285px;
    }
    tbody > tr {
      border-bottom: 1px solid #e2e2e2;
      cursor: pointer;
      td {
        padding-inline: 16px;
        color: #6c6c6c;
        font-weight: 500;
        vertical-align: middle;

        &:nth-child(1),
        &:nth-child(2) {
          text-align: left;
        }

        &:nth-child(2) {
          max-width: 420px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: initial;

          &[data-is-top='true'] {
            font-weight: 800;
          }
        }
      }
    }
  }

  .banner-grid-slide {
    width: 500px;

    .slide-item {
      display: flex;
      flex-direction: column;
      gap: 16px;

      img {
        aspect-ratio: 243 / 137;
      }
    }
  }
}
.main-section__banner-static {
  @extend .main-section;

  .banner-static {
    display: flex;
    justify-content: space-between;
  }
}
.main-section__banner-slide {
  @extend .main-section;
  margin-bottom: 72px;
}

.news-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1280px;
  margin: 16px auto;

  .breadcrumb {
    li {
      display: inline-flex;
      align-items: center;

      > a,
      > span {
        font-size: 16px;
        font-weight: 500;
      }
      > span {
        cursor: default;
      }

      &:not(:last-child)::after {
        content: '';
        display: inline-block;
        width: 16px;
        aspect-ratio: 1;
        margin-inline: 4px;
        background: url(../../assets/images/news/header/ico-arrow-r.png) no-repeat;
        background-size: cover;
      }
    }
  }

  .weather {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;

    > iframe {
      height: 40px;
      width: 240px;
    }
    > .date {
      font-size: 16px;
      font-weight: 500;
    }
  }
}

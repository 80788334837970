@mixin button-base {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 32px;
  aspect-ratio: 1;
  border: 1px solid #ccc;
  color: #333;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  &[aria-current='page'] {
    color: #fff;
    background-color: #ee174f;
    pointer-events: none;
  }
  &[aria-disabled='true'] {
    color: #ccc;
    opacity: 0.5;
    pointer-events: none;
  }
}

.pagination {
  &__list {
    display: flex;
    justify-content: center;
    gap: 5px;
    margin-block: 20px;
  }

  &__page {
    @include button-base;
    border: none;
    &:focus,
    &:hover {
      &:not([aria-disabled='true']) {
        background-color: #ee174f;
        color: #fff;
      }
    }
  }
  &__first {
    @include button-base;
    > img {
      transform: rotate(90deg);
    }
  }
  &__prev {
    @include button-base;
    > img {
      transform: rotate(0deg);
    }
  }
  &__next {
    @include button-base;
    > img {
      transform: rotate(180deg);
    }
  }
  &__last {
    @include button-base;
    > img {
      transform: rotate(-90deg);
    }
  }
}

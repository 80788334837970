.authn-modal {
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.5);
  &__wrapper {
    position: absolute;
    top: 20%;
    left: 50%;
    width: 450px;
    padding: 20px 30px;
    transform: translateX(-50%);
    background: white;
    border: 1px solid $gray_line_3;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .modify {
      &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
      &__title {
        font-size: 22px;
        font-weight: 700;
        display: flex;
        align-items: center;
        gap: 5px;
      }
      &__logo {
        width: 24px;
        height: 24px;
      }
      &__body {
        border: 1px solid #e3e5e8;
        border-radius: 20px;
      }
      &__item {
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 10px 20px;
        &.bg-skyblue {
          border-radius: 20px 20px 0 0;
          background-color: #fafbfd;
        }
        &__title {
          font-size: 14px;
          font-weight: 500;
        }
        &__nick {
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          color: $main_color_0;
          background: white;
          padding: 10px 20px;
          border: 1px solid #e3e5e8;
          border-radius: 50px;
          margin-bottom: 10px;
        }
        &__input {
          font-size: 18px;
          padding: 0 5px;
          padding-bottom: 10px;
          margin: 10px 0;
          border-bottom: 1px solid black;
        }
      }
      &__footer {
        display: flex;
        justify-content: center;
        gap: 10px;
        font-weight: 500;
        &__button {
          font-size: 15px;
          border: 1px solid $gray_line_3;
          padding: 4px 12px;
          border-radius: 4px;
        }
      }
    }
    &.login {
      align-items: center;
      gap: 15px;
      .login {
        &__header {
          &__title {
            font-size: 18px;
            font-weight: 700;
          }
        }
        &__content {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          gap: 10px;
          &__description {
            font-size: 18px;
          }
          &__box {
            display: flex;
            justify-content: center;
            width: 100%;
            border-radius: 5px;
            gap: 5px;
            &.backC-grey {
              padding: 8px 0;
              background: #ebebeb;
              gap: 20px;
            }
          }
          &__button--radio {
            display: flex;
            align-items: center;
            font-size: 18px;
            &::before {
              content: '';
              border: 3px solid black;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              margin-right: 4px;
              background: white;
            }
            &.on {
              &::before {
                background: $sub_color_4;
              }
            }
          }
          &__text {
            &-label {
              font-size: 16px;
              font-weight: 500;
            }
            &-input {
              padding: 0 8px;
              font-size: 15px;
              border-bottom: 1px solid black;
            }
          }
        }
        &__footer__button {
          border: 1px solid $gray_line_3;
          padding: 4px 12px;
          border-radius: 4px;
        }
      }
    }
  }
  .button {
    &--logout {
      height: 30px;
      font-size: 15px;
      padding: 0 15px;
      border-radius: 4px;
      background: $main_color_0;
      color: white;
    }
    &--cancel {
      color: black;
    }
    &--modify {
      background-color: #000000;
      color: white;
    }
  }
}

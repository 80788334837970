.photo-modal {
  all: revert;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: transparent;
  border: none;
  outline: none;

  &::backdrop {
    background-color: rgba(0, 0, 0, 0.85);
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 1280px;
    height: 100%;
    margin-inline: auto;
  }

  &__image-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 40px;
    padding-inline: 2px;
    margin-bottom: 20px;
  }
  &__image {
    width: auto;
    max-width: calc(100% - 100px - 80px - 4px);

    height: 66vh;
    object-fit: contain;
  }
  &__info {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    align-items: center;
    row-gap: 4px;
    column-gap: 50px;
    color: white;
  }
  &__title {
    grid-column: 1 / 2;
    font-size: 24px;
    font-weight: 700;
  }
  &__description {
    grid-column: 1 / 2;
  }
  &__counter {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    font-size: 21px;
  }
  &__date {
    font-weight: 300;
  }
  &__button {
    width: 35px;
    aspect-ratio: 1;
    &:disabled {
      visibility: hidden;
    }
    &--close {
      position: absolute;
      top: 50px;
      right: 2px;
      &::after,
      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 3px;
        background: white;
      }
      &::after {
        transform: translateY(-50%) rotate(45deg);
      }
      &::before {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
    &--next {
      width: 50px;
      transform: rotate(-90deg);
      background: url(../../../assets/images/icon/arrow_circle.svg) no-repeat center;
    }
    &--prev {
      width: 50px;
      transform: rotate(90deg);
      background: url(../../../assets/images/icon/arrow_circle.svg) no-repeat center;
    }
  }
}

.header-search {
  display: flex;
  width: 270px;
  height: 35px;

  margin-right: 16px;
  padding-inline: 10px;

  background-color: #f3f3f3;
  border: 1px solid #eaeaea;
  border-radius: 17.5px;

  > input[type='search'] {
    flex: 1;
    width: 100%;

    color: #1b1b1b;

    background-color: transparent;
    border: none;
  }

  > button {
    height: 100%;
    aspect-ratio: 1;

    position: relative;

    .icon-search {
      display: block;
      width: 20px;
      aspect-ratio: 1;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      background: url(../../assets/images/icon/ico_search_k.png) no-repeat 50% 50%;
      background-size: cover;
    }
  }
}
.header-search--size-small {
  width: 180px;

  margin-right: 0;
}
